import React from 'react'
import { graphql } from 'gatsby'
import slugify from 'slugify'
import parse from 'html-react-parser'
import { Fade } from 'react-reveal'

import Layout from '../../layout'

import * as styles from './news.module.scss'

const News = ({ data: { markdownRemark: { frontmatter: { description, title, image }, html } }, location }) => (
  <Layout location={location} title={title} description={description} slug={slugify(title).toLowerCase()} components={[]}>
    <main className={styles.main}>
      <div className={styles.header}>
        <Fade top>
          <h1 className={styles.title}>{title}</h1>
        </Fade>
        <Fade top delay={500}>
          <div className={styles.image + ' background-cover-center'} style={{ backgroundImage: `url(${image})` }} />
        </Fade>
      </div>
      <Fade left cascade delay={1000}>
        <div className={styles.content}>
          {html && parse(html)}
        </div>
      </Fade>
    </main>
  </Layout>
)

export default News

export const query = graphql`
  query ($id: String) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        description
        timestamp
        title
        image
      }
      html
    }
  }
`
